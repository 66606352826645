import axios from "axios"
import { useQuery } from "react-query"
import moment from "moment"
import { htmlToPlainText } from "../utils/htmlToPlainText"

type BlogPost = {
    title: string
    link: string
    date: string
    id: number
}

async function getBlogPostsRequest(category = 1, postsNumber = 3) {
    const BLOG_WP_JSON_POST_URL = "https://blog.horizen.io/wp-json/wp/v2/posts"
    const params = {
        _embed: ["wp:featuredmedia"],
        _fields: ["title", "link", "date", "id"],
    }

    const res = await axios.get(
        `${BLOG_WP_JSON_POST_URL}?categories=${category}&per_page=${postsNumber}`,
        { params }
    )

    const modifiedData = res.data.map((post: any) => ({
        ...post,
        title: htmlToPlainText(post.title.rendered),
        date: moment(post.date).format("MMMM DD, YYYY"),
    }))

    return modifiedData as BlogPost[]
}

function useBlogPosts() {
    const { data, isLoading, isError } = useQuery({
        queryKey: "blog-posts",
        queryFn: () => getBlogPostsRequest(),
    })

    return {
        blogPosts: data,
        isBlogPostsLoading: isLoading,
        isBlogPostsError: isError,
    }
}

export default useBlogPosts
