import React from "react"
import ContentContainer from "../../templates/ContentContainer"
import Roadmap from "./Roadmap"

import backgroundImage from "../../assets/BackgroundImages/UpgradeGuide/background1.png"
import roadmapContent from "./roadmapData"

const RoadmapWrapper = () => {
    return (
        <section
            className="lg:pt-60 bg-contain bg-no-repeat bg-[center_-150px]"
            style={{
                backgroundImage: `url(${backgroundImage})`,
            }}
        >
            <ContentContainer>
                <div
                    className="
                        font-bold
                        text-[36px] leading-10
                        lg:text-[50px] lg:leading-[55px]
                      "
                >
                    Roadmap
                </div>
                <div className="pt-20 pb-44">
                    <Roadmap roadmapContent={roadmapContent} />
                </div>
            </ContentContainer>
        </section>
    )
}

export default RoadmapWrapper
