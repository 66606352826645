import React from "react"
import { decodeHtmlEntities } from "../../utils/htmlToPlainText"

type BlogPost = {
    title: string
    date: string
    href: string
}

const BlogPost: React.FC<BlogPost> = ({ title, date, href }) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-3 lg:flex gap-4 md:gap-0">
            <a
                className="flex flex-col gap-y-1 group cursor-pointer"
                href={href}
            >
                <p className="text-[#9EA1AE] group-hover:text-[#0E9DE5]">
                    {date}
                </p>
                <div className="flex gap-x-8">
                    <div className="border-l group-hover:border-l-[#0E9DE5]" />
                    <p className="font-bold text-xl md:text-2xl max-w-[320px] whitespace-pre-wrap py-2">
                        {decodeHtmlEntities(title)}
                    </p>
                </div>
            </a>
        </div>
    )
}

export default BlogPost
