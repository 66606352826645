import React from "react"
import ContentContainer from "../../templates/ContentContainer"
import Diagram from "./Diagram"

const WhatIsHorizen = () => {
    return (
        <section className="bg-horizen-hp-bg text-white">
            <ContentContainer>
                <div className="grid lg:grid-cols-[30%,60%] lg:gap-[10%] py-24">
                    <div
                        className="pb-8
                        font-bold
                        text-[36px] leading-10
                        lg:text-[50px] lg:leading-[55px] lg:max-w-xs
                      "
                    >
                        What is Horizen 2.0?
                    </div>
                    <div className="space-y-5">
                        <p className="font-bold text-[21px] leading-7">
                            Enter Horizen 2.0 — a next-generation EVM platform
                            that is optimized to power ZK applications with
                            enhanced scalability and efficiency.
                        </p>
                        <p className="text-[18px] leading-[25px]">
                            In short, we took the best ideas from our legacy
                            mainchain and EON sidechain to build a more
                            advanced, unified network. This transformative
                            upgrade marks a pivotal milestone in our ZK journey,
                            with support for rich ZK dApps, high-speed and
                            low-cost transactions with the latest proving
                            mechanisms, and unparalleled performance for our
                            users and partners.
                        </p>
                    </div>
                </div>
                <Diagram />
            </ContentContainer>
        </section>
    )
}

export default WhatIsHorizen
