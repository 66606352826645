import React from "react"

export const ZKV = () => (
    <svg
        className="max-w-[70px] lg:max-w-max max-h-[70px] lg:max-h-max"
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
    >
        <rect width="100" height="100" fill="#050506" />
        <path
            d="M62.468 55.12H48.6704L62.3475 71.1858H36.8472V55.0142L23.0029 71.2762H23V82.9999H76.2252V71.2762H76.2222L62.468 55.12Z"
            fill="white"
        />
        <path
            d="M23 17V28.7237H23.0029L36.8472 44.9858V28.6332H62.5564V28.7237H62.4245L48.4548 45.1332H62.2525L76.2222 28.7237H76.2252V17H23Z"
            fill="white"
        />
    </svg>
)

export const ZEN = () => (
    <svg
        className="max-w-[70px] lg:max-w-max max-h-[70px] lg:max-h-max"
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
    >
        <rect width="100" height="100" fill="#0C0C1C" />
        <path
            d="M76.9801 30.8662L71.5198 39.2641C73.8024 43.791 74.6 48.9222 73.7993 53.9284C72.9986 58.9346 70.6404 63.561 67.0597 67.1501C63.479 70.7393 58.8582 73.1085 53.8539 73.921C48.8496 74.7335 43.7165 73.948 39.1842 71.6761L30.7864 77.0708C37.1688 81.6036 44.9501 83.7301 52.7508 83.0734C60.5514 82.4167 67.8676 79.0192 73.4024 73.4831C78.9372 67.947 82.333 60.63 82.9879 52.8292C83.6427 45.0283 81.5144 37.2476 76.9801 30.8662Z"
            fill="url(#paint0_radial_7396_172)"
        />
        <path
            d="M50.0625 66.7512C53.9558 66.7542 57.7271 65.3922 60.72 62.9021C63.7129 60.412 65.7381 56.9514 66.4432 53.1224C63.8045 52.5622 61.1142 52.2804 58.4166 52.2815C50.4633 52.2718 42.7082 54.7626 36.248 59.4017C37.7711 61.666 39.8278 63.5208 42.237 64.8025C44.6461 66.0842 47.3336 66.7534 50.0625 66.7512Z"
            fill="url(#paint1_radial_7396_172)"
        />
        <path
            d="M58.2632 49.2455C61.101 49.2514 63.9304 49.5552 66.7047 50.1519C66.7137 47.8687 66.2528 45.6079 65.3506 43.5104C64.4485 41.4129 63.1243 39.5235 61.4606 37.9596C59.7969 36.3958 57.8292 35.191 55.6799 34.4203C53.5306 33.6496 51.2457 33.3293 48.9673 33.4795C46.689 33.6297 44.4659 34.2471 42.4363 35.2933C40.4068 36.3395 38.6143 37.7922 37.1702 39.5608C35.7262 41.3295 34.6616 43.3764 34.0425 45.5742C33.4235 47.772 33.2633 50.0737 33.572 52.336C31.2655 54.0042 29.1714 55.9479 27.3364 58.1239C26.4178 55.5513 25.9487 52.8399 25.9495 50.1083C25.9518 45.9942 27.0062 41.9491 29.0125 38.3574C31.0188 34.7657 33.9104 31.7468 37.4123 29.5877C40.9142 27.4285 44.9102 26.2008 49.0203 26.0213C53.1305 25.8418 57.2182 26.7165 60.895 28.5621L69.2929 23.1019C62.8722 18.5507 55.041 16.4337 47.2018 17.13C39.3625 17.8262 32.0271 21.2901 26.5089 26.9016C20.9907 32.5131 17.6502 39.9056 17.0854 47.7554C16.5207 55.6052 18.7687 63.3998 23.4268 69.7433C24.9908 66.916 26.8941 64.2902 29.0946 61.9242C30.8779 60.063 32.81 58.35 34.8715 56.8025C41.6703 51.8672 49.862 49.2207 58.2632 49.2455Z"
            fill="white"
        />
        <defs>
            <radialGradient
                id="paint0_radial_7396_172"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(77.0019 30.8225) scale(55.76 55.76)"
            >
                <stop offset="0.1" stopColor="#26DB8D" />
                <stop offset="0.26" stopColor="#23D29A" />
                <stop offset="0.56" stopColor="#1ABBBB" />
                <stop offset="0.89" stopColor="#0E9DE5" />
            </radialGradient>
            <radialGradient
                id="paint1_radial_7396_172"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(77.0033 30.8228) scale(55.76 55.76)"
            >
                <stop offset="0.1" stopColor="#26DB8D" />
                <stop offset="0.26" stopColor="#23D29A" />
                <stop offset="0.56" stopColor="#1ABBBB" />
                <stop offset="0.89" stopColor="#0E9DE5" />
            </radialGradient>
        </defs>
    </svg>
)
