import React from "react"
import FeaturedPostPopUp from "../FeaturedPostPopUp"
import ButtonCustomContent from "../ButtonCustomContent"
import { URLProvider } from "../../utils/URLProvider"
import { GAEventName } from "../../utils/sendGAEvent"

const MainBanner = () => {
    return (
        <div className="flex flex-col items-center text-center py-8 lg:py-0">
            <span
                className="text-white font-bold uppercase italic mb-4
            lg:text-[85px] lg:leading-[85px]
            text-[50px] leading-[50px]
            "
            >
                A New Horizen:
            </span>
            <span
                className="text-white font-bold uppercase italic
            lg:text-[85px] lg:leading-[85px]
            text-[50px] leading-[50px]
            my-2 lg:my-0
            "
            >
                2.0 is Rising
            </span>
            <div className="flex justify-center py-9 2xl:py-12">
                <div className="border-b w-12" />
            </div>
            <span className="text-white font-bold max-w-sm lg:max-w-[519px] lg:text-[22px] lg:leading-[27px]">
                The 2.0 upgrade transforms Horizen into a next-gen EVM optimized
                for ZK apps
            </span>

            <div className="flex space-x-3 mt-12">
                <ButtonCustomContent
                    as="internal-link"
                    href={URLProvider.LINK_UPGRADE_GUIDE}
                    variant="primary"
                    gaEventDetails={{
                        eventName: GAEventName.CTAClick,
                        eventParameters: {
                            value: "home_page_hero_learn",
                        },
                    }}
                >
                    Learn more
                </ButtonCustomContent>
                <ButtonCustomContent
                    as="external-link"
                    href={URLProvider.URL_HORIZEN_2_0_WHITEPAPER_DOWNLOAD}
                    gaEventDetails={{
                        eventName: GAEventName.CTAClick,
                        eventParameters: {
                            value: "home_page_hero_wp",
                        },
                    }}
                    openNewTab
                    variant="secondary"
                >
                    Whitepaper
                </ButtonCustomContent>
            </div>
            <FeaturedPostPopUp />
        </div>
    )
}

export default MainBanner
