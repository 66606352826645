import React from "react"
import ContentContainer from "../../templates/ContentContainer"
import { Circle, Cube, Hexagon, Romboid } from "./Icons"

const WhyHorizenGridItem = ({ icon, title, description }) => {
    return (
        <div className="border-t first:border-t-0 lg:first:border-t pt-7 flex">
            <div className="mr-3 lg:mr-9 min-w-[72px]">{icon}</div>
            <div>
                <p className="font-bold text-[22px] leading-[27px]">{title}</p>
                <p className="text-horizen-content-grey leading-[22px] mt-4">
                    {description}
                </p>
            </div>
        </div>
    )
}

const WhyHorizen = () => {
    return (
        <section className="py-32 lg:pt-48 lg:pb-0">
            <ContentContainer>
                <h3
                    className="font-bold
                    text-[36px] leading-10
                    lg:text-[50px] lg:leading-[55px]
                    "
                >
                    Why the “2.0”?
                </h3>
                <p className="text-[24px] leading-[30px] max-w-[849px] my-8 font-[600] mb-[70px] lg:mb-[140px]">
                    Horizen 2.0 is a substantial leap forward for our ecosystem,
                    solving some of the biggest limitations in ZK, enhancing the
                    EVM capabilities, and future-proofing the Horizen network in
                    a modular fashion.
                </p>
                <div className="grid lg:grid-cols-2 gap-16">
                    <WhyHorizenGridItem
                        icon={<Hexagon />}
                        title="EVM Optimized for ZK Apps"
                        description="Designed with a modular architecture that can seamlessly verify ZK proofs while maintaining EVM capabilities, Horizen 2.0 is a highly scalable and interoperable environment for ZK applications."
                    />
                    <WhyHorizenGridItem
                        icon={<Romboid />}
                        title="Optimal Performance at Minimal Cost"
                        description="Horizen 2.0 achieves exceptional performance through tight integration with zkVerify, the most efficient proof verification protocol. This allows for seamless ZK proof verification at minimal cost, so ZK apps can scale effortlessly and maintain optimal performance with high transaction volumes."
                    />
                    <WhyHorizenGridItem
                        icon={<Cube />}
                        title="The Latest Proving Mechanisms"
                        description="Our 2.0 upgrade takes proof verification beyond what’s currently possible on Ethereum. Developers can choose the most suitable proving mechanism directly within smart contracts, resulting in enhanced performance and significant cost savings."
                    />
                    <WhyHorizenGridItem
                        icon={<Circle />}
                        title="Future-Proof by Design"
                        description="Built on an upgradable architecture designed to rapidly incorporate new cryptographic innovations, Horizen 2.0 can stay at the forefront of the ZK space as it continues to evolve."
                    />
                </div>
            </ContentContainer>
        </section>
    )
}

export default WhyHorizen
