import React, { useCallback, useEffect, useRef } from "react"
import { gsap } from "gsap"
import { useQuery } from "react-query"
import { FormattedHTMLMessage } from "gatsby-plugin-intl"
import * as styles from "./FeaturedPostPopUp.module.css"
import { WPMiddleware } from "../sections/OldDesign/news/middlewares"
import { WPConstants } from "../sections/OldDesign/news/Constants"
import { FoldIcon, UnfoldIcon } from "./FeaturedPostPopUpIcons"
import sendGAEvent, { GAEventName } from "../utils/sendGAEvent"

interface PopupContentProps {
    date: string
    title: string
    link: string
}

const PopUpContent: React.FC<PopupContentProps> = ({ date, title, link }) => {
    return (
        <a target="_blank" rel="noopener noreferrer" href={link}>
            <span className="text-white block text-xs">
                <FormattedHTMLMessage id="homepage.featuredPostPopUp.latestNews" />{" "}
                | {date}
            </span>
            <span className="text-white text-2xl font-bold text-ellipsis overflow-hidden my-2 [-webkit-line-clamp:2] [display:-webkit-box] [-webkit-box-orient:vertical]">
                {title}
            </span>
            <span className="text-white block text-xs">
                [{" "}
                <FormattedHTMLMessage id="homepage.featuredPostPopUp.readMore" />{" "}
                ]
            </span>
        </a>
    )
}

const useFoldingAnimation = () => {
    const wrapperRef = useRef(null)
    const contentRef = useRef(null)
    const buttonsWrapperRef = useRef(null)
    const foldBtnRef = useRef(null)
    const unfoldBtnRef = useRef(null)

    const unfoldPopUp = useCallback(() => {
        gsap.to(wrapperRef.current, {
            pointerEvents: "auto",
        })

        gsap.to(contentRef.current, {
            x: "0%",
            duration: 0.25,
        })

        gsap.to(buttonsWrapperRef.current, {
            backgroundColor: "transparent",
            duration: 0.1,
        })

        gsap.to(unfoldBtnRef.current, {
            opacity: 0,
            pointerEvents: "none",
            duration: 0.1,
        })

        gsap.to(foldBtnRef.current, {
            opacity: 1,
            pointerEvents: "auto",
            duration: 0.1,
            delay: 0.1,
        })
    }, [])

    const foldPopUp = useCallback(() => {
        gsap.to(wrapperRef.current, {
            pointerEvents: "none",
        })

        gsap.to(contentRef.current, {
            x: "100%",
            duration: 0.25,
        })

        gsap.to(buttonsWrapperRef.current, {
            backgroundColor: "#0e9de5",
            duration: 0,
        })

        gsap.to(unfoldBtnRef.current, {
            opacity: 1,
            pointerEvents: "auto",
            duration: 0.1,
            delay: 0.1,
        })

        gsap.to(foldBtnRef.current, {
            opacity: 0,
            pointerEvents: "none",
            duration: 0.1,
        })
    }, [])

    const showPopup = useCallback(() => {
        gsap.to(wrapperRef.current, {
            x: "0%",
            duration: 0.5,
        }).then(() => {
            unfoldPopUp()
        })
    }, [unfoldPopUp])

    useEffect(() => {
        gsap.set(wrapperRef.current, {
            x: "100%",
        })

        gsap.set(unfoldBtnRef.current, {
            opacity: 0,
        })
    }, [])

    return {
        wrapperRef,
        contentRef,
        buttonsWrapperRef,
        foldBtnRef,
        unfoldBtnRef,
        showPopup,
        unfoldPopUp,
        foldPopUp,
    }
}

const FeaturedPostPopUp = () => {
    const {
        wrapperRef,
        contentRef,
        buttonsWrapperRef,
        foldBtnRef,
        unfoldBtnRef,
        showPopup,
        unfoldPopUp,
        foldPopUp,
    } = useFoldingAnimation()

    const { data, isSuccess } = useQuery({
        queryKey: "featured-post-popup",
        queryFn: () =>
            WPMiddleware.getPosts({
                perPage: 1,
                page: 1,
                categories: [WPConstants.CATEGORIES.HOMEPAGE_POPUP],
            }),
    })

    function decodeHtmlEntities(text) {
        if (typeof document === "undefined") return text
        const element = document.createElement("div")
        element.innerHTML = text
        return element.textContent
    }

    useEffect(() => {
        if (isSuccess) {
            showPopup()
        }
    }, [isSuccess, showPopup])

    return (
        <>
            <div
                className="fixed bottom-10 right-0 z-[5] overflow-hidden will-change-transform"
                ref={wrapperRef}
                style={{ opacity: isSuccess ? "1" : "0" }}
            >
                <div
                    className="bg-[#0e9de5] w-[90vw] lg:w-[33vw] will-change-transform"
                    ref={contentRef}
                >
                    <div
                        className={`${styles.popup} py-6 px-7 text-left transition-colors ease-out duration-300 will-change-transform`}
                    >
                        <PopUpContent
                            date={data?.posts[0].dateMMMMDDYYYY || ""}
                            title={
                                decodeHtmlEntities(data?.posts[0].title) || ""
                            }
                            link={data?.posts[0].link || ""}
                        />
                    </div>
                </div>

                <div
                    className="absolute right-0 top-0 h-full w-[60px] will-change-transform"
                    ref={buttonsWrapperRef}
                >
                    <button
                        type="button"
                        onClick={() => {
                            foldPopUp()
                            sendGAEvent({
                                eventName: GAEventName.PopUpClick,
                                eventParameters: {
                                    value: "home_page_popup_close",
                                },
                            })
                        }}
                        ref={foldBtnRef}
                        className="h-6 w-6 cursor-pointer absolute top-4 left-1/2 -translate-x-1/2 flex justify-center items-center hover:scale-125 transition-transform ease-out will-change-transform"
                    >
                        <FoldIcon />
                    </button>

                    <button
                        type="button"
                        onClick={() => {
                            unfoldPopUp()
                            sendGAEvent({
                                eventName: GAEventName.PopUpClick,
                                eventParameters: {
                                    value: "home_page_popup_open",
                                },
                            })
                        }}
                        ref={unfoldBtnRef}
                        className="h-6 w-6 cursor-pointer absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex justify-center items-center hover:scale-125 transition-transform ease-out will-change-transform"
                    >
                        <UnfoldIcon />
                    </button>
                </div>
            </div>
        </>
    )
}

export default FeaturedPostPopUp
