import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import ContentContainer from "../templates/ContentContainer"
import BlogPost from "./Blog/BlogPost"
import SectionHeading from "./SectionHeading"
import ButtonCustomContent from "./ButtonCustomContent"
import { GAEventName } from "../utils/sendGAEvent"
import useBlogPosts from "../hooks/useBlogPosts"
import LoadingSpinner from "./ui/LoadingSpinner"

const BlogLastUpdates = () => {
    const { formatMessage } = useIntl()

    const { blogPosts, isBlogPostsError, isBlogPostsLoading } = useBlogPosts()

    return (
        <div className="text-white">
            <ContentContainer>
                <div className="mb-12 lg:mb-24">
                    <SectionHeading>
                        {formatMessage({
                            id: "homepage.blog.title",
                        })}
                    </SectionHeading>
                </div>
                <div className="grid grid-cols-1 lg:grid lg:grid-cols-3 lg:gap-8 space-y-8 lg:space-y-0">
                    {isBlogPostsLoading && (
                        <div className="col-span-3 flex justify-center">
                            <LoadingSpinner />
                        </div>
                    )}
                    {isBlogPostsError && (
                        <div className="col-span-3 flex justify-center">
                            <p className="text-center">
                                No posts available at the moment
                            </p>
                        </div>
                    )}

                    {blogPosts?.map((post) => (
                        <BlogPost
                            key={post.id}
                            title={post.title}
                            date={post.date}
                            href={post.link}
                        />
                    ))}
                </div>
                <div className="mt-20 flex justify-end">
                    <ButtonCustomContent
                        openNewTab
                        as="external-link"
                        variant="primary"
                        href="https://www.horizen.io/news/"
                        gaEventDetails={{
                            eventName: GAEventName.CTAClick,
                            eventParameters: {
                                value: "home_page_newsroom",
                            },
                        }}
                    >
                        NEWSROOM
                    </ButtonCustomContent>
                </div>
            </ContentContainer>
        </div>
    )
}

export default BlogLastUpdates
