import { Helmet } from "react-helmet"
import React from "react"

import Hero from "../components/HomepageHero"
import LatestUpdatesBackground from "../assets/LatestUpdatesBackground.png"
import Layout from "../templates/Layout"
import NewsletterForm from "../components/NewsletterForm"
import BlogLastUpdates from "../components/BlogLastUpdates"
import WhatIsHorizen from "../components/Horizen2.0/WhatIsHorizen"
import WhyHorizen from "../components/Horizen2.0/WhyHorizen"
import StayInformed from "../components/Horizen2.0/StayInformed"
import Airdrop from "../components/Horizen2.0/Airdrop"
import RoadmapWrapper from "../components/Horizen2.0/RoadmapWrapper"

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataLayer: any
        gtag: any
    }
}

const Home = () => {
    return (
        <Layout>
            <Helmet>
                <title>Horizen - The Next-gen EVM Optimized for ZK Apps</title>
                <meta
                    property="og:title"
                    content="Horizen - The Next-gen EVM Optimized for ZK Apps"
                />
                <meta
                    property="og:image"
                    content="https://horizen.io/assets/images/meta/homepage.jpg"
                />
                <meta
                    name="description"
                    content="Horizen solves some of the biggest limitations in ZK, enhancing the EVM capabilities with the latest and most efficient proof verification mechanisms at a minimal cost."
                />
                <meta
                    property="og:description"
                    content="Horizen solves some of the biggest limitations in ZK, enhancing the EVM capabilities with the latest and most efficient proof verification mechanisms at a minimal cost."
                />
                <meta
                    name="twitter:title"
                    content="Horizen - The Next-gen EVM Optimized for ZK Apps"
                />
                <meta
                    name="twitter:description"
                    content="Horizen solves some of the biggest limitations in ZK, enhancing the EVM capabilities with the latest and most efficient proof verification mechanisms at a minimal cost."
                />
                <meta
                    name="twitter:image"
                    content="https://horizen.io/assets/images/meta/homepage.jpg"
                />
            </Helmet>
            <div>
                <main className="min-h-[calc(100vh-64px-48px)] bg-horizen-hp-bg text-white ">
                    <div>
                        <Hero />
                        <WhatIsHorizen />
                        <WhyHorizen />
                        <RoadmapWrapper />
                        <Airdrop />
                        <StayInformed
                            gaEventDetails={{
                                joinCommunity: "home_page_join_community",
                                followX: "home_page_follow_x",
                            }}
                        />
                        <div
                            className="bg-contain"
                            style={{
                                backgroundImage: `url(${LatestUpdatesBackground})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPositionY: "30%",
                            }}
                        >
                            <div className="py-24 md:pb-52 md:pt-40 ">
                                <BlogLastUpdates />
                            </div>
                        </div>
                        <NewsletterForm />
                    </div>
                </main>
            </div>
        </Layout>
    )
}

export default Home
