import React from "react"
import ContentContainer from "../../templates/ContentContainer"
import ButtonCustomContent from "../ButtonCustomContent"
import { URLProvider } from "../../utils/URLProvider"
import { GAEventName } from "../../utils/sendGAEvent"

type StayInformedProps = {
    gaEventDetails: {
        joinCommunity: string
        followX: string
    }
}

const StayInformed = ({ gaEventDetails }: StayInformedProps) => {
    return (
        <section className="pt-28 lg:pt-52">
            <ContentContainer>
                <div className="border pt-9 pb-11 pl-6 pr-8 lg:pl-16 lg:pr-14 flex flex-col lg:flex-row justify-between lg:items-center">
                    <p className="font-bold text-[22px] leading-[27px] max-w-[270px] mb-10 lg:mb-0">
                        Stay Informed and Engage with the Community
                    </p>
                    <div className="flex flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-3">
                        <ButtonCustomContent
                            as="external-link"
                            href={URLProvider.URL_DISCORD}
                            openNewTab
                            variant="primary"
                            gaEventDetails={{
                                eventName: GAEventName.CTAClick,
                                eventParameters: {
                                    value: gaEventDetails?.joinCommunity,
                                },
                            }}
                        >
                            Join the community
                        </ButtonCustomContent>
                        <ButtonCustomContent
                            as="external-link"
                            href={URLProvider.URL_TWITTER}
                            openNewTab
                            variant="primary"
                            gaEventDetails={{
                                eventName: GAEventName.CTAClick,
                                eventParameters: {
                                    value: gaEventDetails?.followX,
                                },
                            }}
                        >
                            Follow us on X
                        </ButtonCustomContent>
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default StayInformed
