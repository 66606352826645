import React from "react"
import ContentContainer from "../templates/ContentContainer"
import MainBanner from "./Hero/MainBanner"
import bgVideo from "../assets/video/ZBF_homepage_background_video_JUN23.mp4"
import bgVideoPosterMobile from "../assets/video/Main-mobile-image.png"
import bgVideoPoster from "../assets/video/Horizen_LoopVideo_LoodingFrame.jpg"

const HomepageHero = () => {
    return (
        <>
            <div className="w-full relative lg:pt-[250px] xl:-mt-[130px]">
                <video
                    loop
                    autoPlay
                    muted
                    poster={bgVideoPoster}
                    className="hidden lg:block lg:w-full h-auto
                lg:object-cover"
                >
                    <source src={bgVideo} type="video/mp4" />
                </video>
            </div>
            <div
                className="bg-horizen-hp-bg lg:absolute top-0 right-0 w-full pt-32 sm:pt-[57px] md:pt-60 bg-cover bg-no-repeat lg:bg-opacity-0 lg:!bg-none bg-[position:100%_100%] md:bg-[position:50%_100%]"
                style={{
                    backgroundImage: `url(${bgVideoPosterMobile})`,
                    backgroundSize: "contain",
                }}
            >
                <ContentContainer>
                    <MainBanner />
                </ContentContainer>
            </div>
        </>
    )
}

export default HomepageHero
