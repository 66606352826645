import React from "react"
import Modal from "react-modal"
import NewsletterForm from "../NewsletterForm"
import { Cross } from "./Icons"
import { NEWSLETTER_AIRDROP_LIST_ID } from "../../utils/constants"

const AirdropModal = ({
    modalIsOpen,
    toggleModal,
}: {
    modalIsOpen: boolean
    toggleModal: () => void
}) => {
    Modal.setAppElement("body")

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={toggleModal}
            contentLabel="Modal for getting notified for airdrop"
            style={{
                overlay: {
                    backgroundColor: "rgba(12, 12, 28, 0.4)",
                    backdropFilter: "blur(2px)",
                    WebkitBackdropFilter: "blur(2px)",
                },
            }}
            className="absolute outline-none 
    px-6 md:px-12 lg:px-8 xl:px-2 w-full max-w-screen-xl
    top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
    bg-[rgba(12,12,28,0.70)]"
            bodyOpenClassName="overflow-hidden"
        >
            <div className="border border-white px-6 py-4 relative">
                <button
                    type="button"
                    className="cursor-pointer outline-none absolute top-1 right-1"
                    onClick={toggleModal}
                    tabIndex={0}
                    onKeyDown={(e) => {
                        if (e.key === " ") {
                            toggleModal()
                        }
                    }}
                >
                    <Cross />
                </button>
                <NewsletterForm
                    inputsOnly
                    newsletterID={NEWSLETTER_AIRDROP_LIST_ID}
                />
            </div>
        </Modal>
    )
}

export default AirdropModal
