import React, { useState } from "react"
import ContentContainer from "../../templates/ContentContainer"
import { ZEN, ZKV } from "./Logos"
import ButtonCustomContent from "../ButtonCustomContent"
import AirdropModal from "./AirdropModal"
import { URLProvider } from "../../utils/URLProvider"
import { GAEventName } from "../../utils/sendGAEvent"

const AirdropGridItem = ({ icon, title, description }) => {
    return (
        <div className="border-t pt-7 flex items-start">
            <div className="mr-9">{icon}</div>
            <div>
                <p className="font-bold text-[35px] leading-[40px]">{title}</p>
                <p className="text-horizen-content-grey text-[18px] leading-[25px] mt-4">
                    {description}
                </p>
            </div>
        </div>
    )
}

const Airdrop = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const toggleModal = () => setModalIsOpen(!modalIsOpen)

    return (
        <section className="bg-old-design-dark-blue lg:bg-horizen-hp-bg text-white">
            <ContentContainer>
                <div
                    className="bg-old-design-dark-blue pt-20 pb-28 lg:px-16 relative 
                lg:after:absolute lg:after:w-2 lg:after:h-full lg:after:border lg:after:top-0 lg:after:right-0 lg:after:border-l-0 
                lg:before:absolute lg:before:w-2 lg:before:h-full lg:before:border lg:before:top-0 lg:before:left-0 lg:before:border-r-0
                2xl:px-48
                "
                >
                    <div className="flex flex-col lg:flex-row lg:justify-between mb-28">
                        <p className="font-bold text-[50px] leading-[55px] max-w-md">
                            Prospective zkVerify Token Claim
                        </p>
                        <div className="max-w-[648px]">
                            <p className="text-[21px] leading-7 font-bold my-12 lg:my-0">
                                Eligible ZEN holders are anticipated to
                                participate in a claim of some allocation of the
                                initial zkVerify token supply at the launch of
                                zkVerify mainnet. More information is
                                forthcoming.
                            </p>
                            <div className="flex flex-col lg:flex-row lg:space-x-6 space-y-6 lg:space-y-0 lg:mt-16">
                                <ButtonCustomContent
                                    as="button"
                                    onClick={toggleModal}
                                    variant="primary"
                                    gaEventDetails={{
                                        eventName: GAEventName.CTAClick,
                                        eventParameters: {
                                            value: "home_page_zkv_notify",
                                        },
                                    }}
                                >
                                    Get Notified
                                </ButtonCustomContent>
                                <ButtonCustomContent
                                    as="internal-link"
                                    href={URLProvider.LINK_EXCHANGES}
                                    variant="secondary"
                                    gaEventDetails={{
                                        eventName: GAEventName.CTAClick,
                                        eventParameters: {
                                            value: "home_page_get_zen",
                                        },
                                    }}
                                >
                                    Get zen
                                </ButtonCustomContent>
                            </div>
                            <AirdropModal
                                modalIsOpen={modalIsOpen}
                                toggleModal={toggleModal}
                            />
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-[39.5%,54%] gap-[6.5%] space-y-4 lg:space-y-0">
                        <AirdropGridItem
                            title="About zkVerify Token"
                            description={
                                <span>
                                    The zkVerify token will be the utility token
                                    for the{" "}
                                    <a
                                        href="https://zkverify.io"
                                        className="underline"
                                    >
                                        zkVerify network
                                    </a>{" "}
                                    and the governance token for the future
                                    zkVerify ecosystem. It is expected that this
                                    token will be created and launched
                                    coinciding with zkVerify mainnet going live,
                                    but ultimately will be dependent on the
                                    token issuer.
                                </span>
                            }
                            icon={<ZKV />}
                        />
                        <AirdropGridItem
                            title="About ZEN"
                            description={
                                <span>
                                    The native cryptocurrency of the Horizen
                                    Ecosystem.
                                    <ul className="list-inside list-disc mt-3">
                                        <li>
                                            One of the first ZK coins that was
                                            fair launched with no pre-mine or
                                            ICO
                                        </li>
                                        <li>
                                            Fixed 21 million supply just like
                                            Bitcoin
                                        </li>
                                        <li>
                                            ZEN will be the payment currency for
                                            staking and gas fees on Horizen 2.0
                                        </li>
                                        <li>
                                            ZEN will continue to be the
                                            governance token for the
                                            decentralized Horizen 2.0 ecosystem
                                        </li>
                                    </ul>
                                </span>
                            }
                            icon={<ZEN />}
                        />
                    </div>
                </div>
            </ContentContainer>
        </section>
    )
}

export default Airdrop
